<template>
  <div class="payment-method-form" :class="!isShowTypeOptions ? 'col-12 pl-0 pr-0' : 'w-100 m-0'">
    <div :class="{ 'hide-payment-method-form': loadingPaymentMethods }">
      <div v-if="isShowTypeOptions" class="choose-payment-header">
        <header>Choose a Payment Method</header>
        <div class="button-group">
          <b-button
            variant="outline-primary"
            class="mr-2"
            aria-label="credit or debit card button"
            @click="setSelectedPayableType('card')"
          >
            <b-img :src="`/images/creditCards/default.svg`" class="mr-2" />
            <span>
              Credit/Debit Card
            </span>
          </b-button>
          <b-button
            variant="outline-primary"
            class="mr-2"
            aria-label="ach button"
            @click="setSelectedPayableType('automatedClearingHouse')"
          >
            <b-img :src="`/images/automatedClearingHouses/ach.svg`" class="mr-2" />
            <span>
              ACH
            </span>
          </b-button>
        </div>

        <!--            <digital-wallet-survey />-->

        <!--            <b-button-->
        <!--              variant="outline-primary"-->
        <!--              class="mr-2"-->
        <!--              @click="setPayableType('paypal')"-->
        <!--            >-->
        <!--              <b-img :src="`/images/digitalWallets/paypal.svg`" class="mr-2" />-->
        <!--              <span>-->
        <!--                PayPal-->
        <!--              </span>-->
        <!--            </b-button>-->
        <!--            <b-button-->
        <!--              variant="outline-primary"-->
        <!--              class="mr-2"-->
        <!--              @click="setPayableType('apple pay')"-->
        <!--            >-->
        <!--              <b-img :src="`/images/digitalWallets/apple pay.svg`" class="mr-2" />-->
        <!--              <span>-->
        <!--                Apple Pay-->
        <!--              </span>-->
        <!--            </b-button>-->
      </div>

      <div class="payment-information">
        <header>Payment Information</header>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <p v-if="paymentFormDescription !== ''" class="text-center">
            <em>{{ paymentFormDescription }}</em>
          </p>
          <b-form ref="form" @submit.prevent="savePaymentInfo">
            <div v-if="isTypeCard">
              <credit-card-form
                :edit-card="paymentMethodSelection"
                @changed="paymentMethodInfoChanged"
              />
              <header>Address Information</header>
              <address-form
                :allow-existing-addresses="true"
                :edit-address="selectedAddress"
                :show-kind="false"
                @changed="addressInfoChanged"
              />
            </div>
            <div v-if="isTypeACH">
              <automated-clearing-house-form
                :edit-ach="paymentMethodSelection"
                @changed="paymentMethodInfoChanged"
              />
            </div>

            <b-form-row class="submit-section">
              <payment-method-info-agreement />
              <div id="btn-submit">
                <button :disabled="!infoAgreement" class="btn btn-primary submit-btn submit-btn-width" type="submit">
                  {{ !editMode ? submitButtonText : updateButtonText }}
                </button>
              </div>
            </b-form-row>
            <b-tooltip
              v-if="invalid"
              :target="`btn-submit`"
              placement="top"
              :triggers="'hover focus'"
            >
              Please fill out the rest of the form.
            </b-tooltip>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <div v-if="loadingPaymentMethods" class="bg-white">
      <ct-centered-spinner />
    </div>
    <duplicate-payment-method-modal
      @cancelAddPaymentMethod="cancelAddPaymentMethod"
      @updatePreviousPaymentMethod="updatePreviousPaymentMethod"
      @close="cancelAddPaymentMethod"
    />
  </div>
</template>

<script>

import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'
import TokenizerClient from '@/common/modules/tokenizer-client'
import _ from 'lodash'
import CreditCardForm from './shared/forms/CreditCardForm'
import AutomatedClearingHouseForm from './shared/forms/AutomatedClearingHouseForm.vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions, mapGetters } from 'vuex'
import AddressForm from './shared/forms/AddressForm'
import CtCenteredSpinner from './shared/CtCenteredSpinner'
import DuplicatePaymentMethodModal from '@/components/DuplicatePaymentMethodModal'
import PaymentMethodInfoAgreement from '@/components/Payments/PaymentMethodInfoAgreement'
// import DigitalWalletSurvey from '@/components/Payments/DigitalWalletSurvey'

export default {
  name: 'PaymentMethodForm',
  components: {
    AddressForm,
    AutomatedClearingHouseForm,
    CreditCardForm,
    CtCenteredSpinner,
    DuplicatePaymentMethodModal,
    PaymentMethodInfoAgreement,
    ValidationObserver,
  },
  mixins: [paymentMethodsMixin],
  props: {
    submitButtonText:{
      type: String,
      default: 'Add as Payment Method',
    },
    updateButtonText:{
      type: String,
      default: 'Update Payment Method',
    },
    paymentFormDescription:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paymentMethodInfo: {},
      addressInfo: {},
      errors: [],
      selectedAddress: null,
      tokenizerClient: new TokenizerClient(this.$store),
    }
  },
  computed: {
    ...mapGetters(
      'account', ['account'],
    ),
    ...mapGetters(
      'website', ['website', 'isPurchaseCanaryWebsite']
    ),
    paymentMethodSelection() {
      return this.selectedPaymentMethod
    },
    editMode() {
      return !!this.selectedPaymentMethod
    },
    isShowTypeOptions() {
      return !this.editMode && !this.isPaymentMethodsPage && this.isPurchaseCanaryWebsite
    },
  },
  mounted() {
    if (this.editMode && this.isTypeCard) {
      this.selectedAddress = this.tokenizerClient.addressFromTokenizer(this.selectedPaymentMethod.billing_address)
    }
  },
  methods: {
    ...mapActions('account', ['createPersonAddress']),
    paymentMethodInfoChanged(info) {
      this.paymentMethodInfo = info
    },
    addressInfoChanged(info) {
      this.addressInfo = info
    },
    async savePaymentInfo() {
      const valid = await this.$refs.observer.validate()

      if (!valid) return

      if (this.isTypeACH) {
        this.paymentMethodInfo.agreement_indicator = true
      }

      const tokenizerClient = new TokenizerClient(this.$store)

      if (this.isTypeCard) {
        // We formerly "prevented edit on card numbers to avoid exposing them". However, we are allowing it now.
        this.paymentMethodInfo.billing_address = tokenizerClient.addressToTokenizer(this.addressInfo)
      } else if (this.isTypeACH) {
        if (!this.paymentMethodInfo.agreement_indicator) {
          this.loadedPaymentMethods()
          return
        } else if (this.selectedPaymentMethod?.bank_account_number === this.paymentMethodInfo?.bank_account_number) {
            delete this.paymentMethodInfo?.bank_account_number
        }
      }

      const result = this.paymentMethodInfo.id ?
        await tokenizerClient.updatePaymentMethod(this.paymentMethodInfo, this.selectedPayableType) :
        await tokenizerClient.createPaymentMethod(this.paymentMethodInfo, this.selectedPayableType)
      await this.verifyResult(result)
    },
    cancelAddPaymentMethod() {
      this.$bvModal.hide('payment-method-modal')
      this.loadedPaymentMethods()
    },
    async updatePreviousPaymentMethod() {
      const tokenizerClient = new TokenizerClient(this.$store)
      const result = await tokenizerClient.updatePaymentMethod(this.paymentMethodInfo, this.selectedPayableType)
      await this.verifyResult(result)
    },
    async verifyResult(result) {
      result.success ? await this.successfulResult(result) : await this.unSuccessfulResult(result)
    },
    async successfulResult(result) {
      await this.logEditExpiredCardInteraction(result.data)
      const addedPayableId = result.data?.id
      this.$emit('update', addedPayableId)
    },
    async unSuccessfulResult(result) {
      // after duplicate add attempt, keep form in scope and add the duplicate id to allow update with form values
      if (result.data?.message === 'Duplicate Payment Method') {
        this.paymentMethodInfo.id = result.data.duplicateId
        this.$bvModal.show('payment-method-duplicate-card-modal')
        return
      }

      if (_.isObjectLike(result.data) && result.data.errors) {
        this.errors = Object.keys(result.data.errors).map(key =>
          `${_.startCase(key)}: ${_.startCase(result.data.errors[key])}`
        )
      } else if (result.data.message === 'Bad card Issuer') {
        this.$emit('badCardIssuer')
        this.errors = "This card is not allowed by our processor. Please use a different card."
      } else if (result.data.message === 'Tokenizer Error') {
        this.errors = `There was an issue processing your ${this.printeablePaymentType}. Please try again later.`
      } else {
        const provider = this.isTypeCard ? 'card provider' : 'bank'
        this.errors = `There was an issue with your ${this.printeablePaymentType}. Please contact your ${provider} or try another payment method.`
      }

      this.$emit('update', this.paymentMethodInfo?.id, this.errors)
    },
    async logEditExpiredCardInteraction(updatedPayable) {
      if (this.editMode && !updatedPayable.isExpired) {
        await this.logExpiredCardInteraction('edit', updatedPayable.id)
      }
    },
  },
}
</script>

<style scoped lang="scss">

  .payment-method-form {
    margin: auto !important;

    .hide-payment-method-form {
      height: 0;
      opacity: 0;
    }

    .submit-section {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    header {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-left: 15px;
    }

    .choose-payment-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 2rem 3rem 0 3rem;
      background-color: white;

      header {
        margin-bottom: 1.5rem;
      }

      .button-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
      }

      .btn-outline-primary {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 141px;
        height: 48px;
        border-radius: 6px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        color: #7D7D7D;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;

        &:hover, &:active {
          border-radius: 6px;
          border: 1px solid #7D7D7D !important;
          background: #F1F1F1 !important;
          color: #363636 !important;
        }
      }
    }

    .payment-information {
      background: #F1F1F1;
      padding: 2rem 3rem 3rem 3rem;

      header {
        margin-top: 8px;
        margin-bottom: 16px;
      }

      // button styling
      .submit-btn {
        border-radius: 4px !important;
        padding: 8px 16px 8px 10px !important;
      }

      ::v-deep .payment-method-form-container {

        .col-form-label {
          color: #000;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .form-control {
          min-height: 41px !important;
          height: 41px !important;
          border-radius: 6px;
        }

        .custom-select {
          min-height: 41px !important;
          height: 41px !important;
          border-radius: 6px;
        }

        .custom-control-label {
          color: #000;
          font-size: 14px !important;
          font-style: normal;
          font-weight: 400 !important;
          line-height: normal;
        }

        .agreement-section{
          margin-bottom: 12px;

          .sample-check-text {
            color: #848A9D;
            text-align: left;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 3px;
          }

          .sample-check {
            width: 400px;
            height: auto;

            @media only screen and (max-width: 550px) {
              width: 300px;
            }
          }
        }
      }
    }
  }
</style>
