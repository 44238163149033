import { mapActions, mapGetters } from 'vuex'
import { createOrFindClientInteractionLog } from '@/common/modules/clientInteractionLog'

export const paymentMethodsMixin = {
  computed: {
    ...mapGetters(
      'checkout', [
        'agreementAccepted',
        'selectedPaymentMethod',
      ]
    ),
    ...mapGetters(
      'paymentMethods', [
        'automatedClearingHouses',
        'cards',
        'customerToken',
        'digitalWallets',
        'infoAgreement',
        'isExpiredCardConversion',
        'isSelectedExpired',
        'loadingPaymentMethods',
        'selectedPaymentMethod',
        'selectedPayableType',
        'currentTotal',
        'exceedsAchTransactionLimit',
      ]
    ),
    ...mapGetters('website', [
      'isPurchaseCanaryWebsite',
    ]),
    isTypeACH() {
      return this.selectedPayableType === 'automatedClearingHouse'
    },
    isTypeCard() {
      return this.selectedPayableType === 'card'
    },
    isPaymentMethodsPage() {
      return this.$route.path === '/account/payment-methods'
    },
    printeablePaymentType() {
      return this.isTypeCard ? 'Card' : 'ACH'
    },
  },
  data() {
    this.badCardIssuer = false
  },
  watch: {
    currentTotal(newValue) {
      this.setExceedsAchTransactionLimit(newValue > 8000)
    },
  },
  methods: {
    ...mapActions('checkout', [
      'setAgreementAccepted',
    ]),
    ...mapActions('paymentMethods', [
      'loadPaymentMethods',
      'loadedPaymentMethods',
      'refreshPaymentMethods',
      'resetPaymentMethod',
      'setIsExpiredCardConversion',
      'setPaymentMethod',
      'setSelectedPaymentMethod',
      'setSelectedPayableType',
      'setCurrentTotal',
      'setExceedsAchTransactionLimit',
      'resetCurrentTotal',
    ]),
    async retryPaymentMethods() {
      if (this.customerToken === null) {
        await this.refreshPaymentMethods()
      }
    },
    displayResultToast(payableId, isEditMode, errors = null, deleted = false) {
      const action = isEditMode ? 'Update' : 'Add'
      const acted = isEditMode ? 'Updated' : 'Added'
      let body = `Your ${this.printeablePaymentType} has been Successfully ${acted}`
      let title = `${this.printeablePaymentType} ${acted}`
      let variant = 'success'

      if (errors) {
        variant = 'danger'
        title = `Couldn't ${action} ${this.printeablePaymentType}`
        body = errors
      } else if (deleted) {
        title = `Your ${this.printeablePaymentType} has been Successfully Removed`
        body = `${this.printeablePaymentType} Removed`
      }

      this.$bvToast.toast(body, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 5000,
      })

      this.badCardIssuer = false

      this.loadedPaymentMethods()
    },
    setBadCardIssuer() {
      this.badCardIssuer = true
    },
    async logExpiredCardInteraction(action, cardId) {
      if (this.isExpiredCardConversion && this.isTypeCard && this.isSelectedExpired) {
        await createOrFindClientInteractionLog({
          category: 'page-interaction',
          subCategory: 'payment-methods-page',
          interaction: {
            type: 'button',
            action: action,
            name: 'expired-card-conversion',
          },
          completeLog: true,
          objectId: cardId,
          objectTable: 'Card',
        })
      }
    },
  },
}
